





























import { Component, Vue, Watch } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import CookiePolicy from "@/types/CookiePolicy/CookiePolicy"
import CookiePolicyApi from "@/api/CookiePolicy/CookiePolicyApi"

import TitleShape from "@/components/TitleShape.vue"
import ViewWrapper from "@/views/ViewWrapper.vue"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import CompetitionStrategy from "@/components/AboutUs/CompetitionStrategyPage.vue"
import CompanyProfilePage from "@/components/AboutUs/CompanyProfilePage.vue"
import ModalSettingPolicy from "@/components/Global/ModalSettingPolicy.vue"

@Component({
  name: "CookiesPolicy",
  components: {
    ViewWrapper,
    CompanyProfilePage,
    CompetitionStrategy,
    TitleShape,
    SectionUnderLine,
    ModalSettingPolicy
  }
})
export default class CookiesPolicy extends Vue {
  private cookies: CookiePolicy = new CookiePolicy()

  private isActive: boolean = false

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    CookiePolicyApi.getCookiePolicy().then(({ data }) => {
      this.cookies = deserialize<CookiePolicy>(data, CookiePolicy)
    })
  }

  mounted() {
    this.fetchData()
  }

  private settingPolicy() {
    this.isActive = true
  }

  private submitSetting() {
    this.isActive = false
  }
}
